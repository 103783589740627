<template>
  <v-dialog v-model="isShown" content-class="select-city-popup__dialog">
    <v-card class="select-city-popup">
      <div class="select-city-popup__header d-flex justify-space-between mb-8">
        <h2>Выберите город</h2>
        <svgicon name="cross" class="select-city-popup__close" @click="onCloseClick"/>
      </div>

      <div class="select-city-popup__search">
        <v-select v-model="search"
                  :items="items"
                  item-text="name"
                  item-value="id"
                  return-object
                  outlined
                  hide-no-data/>

        <div class="select-city-popup__search-button">
          <btn-primary large @click="onConfirmClick" :loading="loading">Подтвердить</btn-primary>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import BtnPrimary from "@/components/btn-primary.vue";
import BtnMinor from "@/components/btn-minor.vue";
import Cookies from "js-cookie";
import city from "@/mixins/city";
import config from "@/config";

export default {
  name: 'select-city-popup',
  components: {BtnMinor, BtnPrimary},
  emits: ['close'],
  mixins: [city],
  data() {
    return {
      search: null,
      loading: false,
    }
  },
  computed: {
    city() {
      return this.currentCity;
    },
    cities() {
      return this.$store.state.common.cities;
    },
    items() {
      return this.cities.map(c => {
        return {
          id: c.ID,
          name: c.NAME,
        }
      });
    },
    isShown: {
      get() {
        return this.$store.state.isSelectCityPopupOpen;
      },
      set(newValue) {
        this.$store.commit('setIsSelectCityPopupOpen', newValue);
      }
    }
  },
  watch: {
    isShown(shown) {
      if (shown) {
        const isCityExists = this.items.find(el => el.id === this.$store.state.cityId);
        if (isCityExists) {
          this.search = { id: this.$store.state.cityId, name: this.$store.state.cityName };
        } else {
          this.search = { id: config.CITIES[2].id, name: config.CITIES[2].name };
        }
      }
    }
  },
  methods: {
    onCloseClick() {
      this.$store.commit('setIsSelectCityPopupOpen', false);
    },
    onConfirmClick() {
      this.loading = true;

      this.$store.dispatch('post', {
        action: 'CityChangeController',
        params: {
          cityId: this.search.id,
        }
      }).then(() => {
        // then
      }).catch((err) => {
        //console.error('Не удалось сохранить город. Ошибка: ', err);
        // error
      }).finally(() => {
        this.loading = false;
      });

      this.$store.commit('setCurrentCity', {cityId: this.search.id, cityName: this.search.name});
      localStorage.setItem('cityId', this.search.id);
      localStorage.setItem('cityName', this.search.name);
      localStorage.setItem('isCityConfirmed', '1');
      this.$store.commit('setIsSelectCityPopupOpen', false);
    }
  },
}
</script>

<style lang="scss">
.select-city-popup {
  padding: 30px 30px;
  border-radius: 0 !important;

  &__dialog {
    border-radius: 0 !important;
    box-shadow: none !important;
    max-width: 90% !important;

    @include up($md) {
      max-width: 30% !important;
    }
  }

  &__header {
    svg {
      width: 15px;
      height: 15px;

      path {
        stroke: $black;
      }
    }
  }

  &__close {
    cursor: pointer;
    @include transition();

    &:hover {
      opacity: 0.5;
    }
  }

  &__search {
    &-button {
      & .btn-primary {
        min-width: 130px !important;
      }
    }
  }
}
</style>